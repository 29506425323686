// src/post/datasources/cmsCategoriesData.ts

import type { CmsCategoryIds } from "~/src/post/domain/models/Post";
import type { WebContext } from "~/src/Shared/Types";

const cmsCategoriesKings: CmsCategoryIds = {
  0: {},
  1: { es: { general: 16, featured: 21, external: 8 } },
  2: { es: { general: 16, featured: 21, external: 8 } },
  3: { es: { general: 142, featured: 143, external: 8 } },
};

const cmsCategoriesQueens: CmsCategoryIds = {
  0: {},
  1: { es: { general: 17, featured: 22, external: 18 } },
  2: { es: { general: 125, featured: 131, external: 132 } },
};

export const getWebContextCmsCategories = (webContext: WebContext): CmsCategoryIds => {
  if (webContext === "queens") {
    return cmsCategoriesQueens;
  } else {
    return cmsCategoriesKings;
  }
};
