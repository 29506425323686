// src/post/infrastructure/ui-services/postUiService.ts

import type { Post } from "~/src/post/domain/models/Post";
import type { NitroFetchOptions } from "nitropack";
import { useWebContext } from "~/nuxt-ui/Shared/composables/webContext";
import { useCompetitionStore } from "~/nuxt-ui/stores/competitionStore";
import { $FEATURED_ITEMS } from "~/src/post/domain/service/PostService";
import { getWebContextCmsCategories } from "~/src/post/datasources/cmsCategoriesData";

interface PostUiService {
  getFeaturedPosts: (lang: string) => Promise<Post[] | undefined>;
  getPosts: (opts: NitroFetchOptions<any>) => Promise<Post[] | undefined>;
  getPost: (slug: string, lang: string) => Promise<Post | undefined>;
  isExternal: (categoryIds: number[]) => boolean;
  parseString: (title: string) => string;
  localeHasNews: (lang?: string) => boolean;
  // checkRepeatedPostInRegions: (
  //   postCategories: number[],
  //   webContext: WebContext
  // ) => { repeated: boolean; cmsKey: string };
}

const localeHasNews = (lang?: string): boolean => {
  const { locale, currentRegionId, webContext } = getRequiredContextData();
  const newsCategories = getWebContextCmsCategories(webContext);
  if (!lang && currentRegionId) {
    return !!newsCategories[currentRegionId] && !!newsCategories[currentRegionId][locale];
  } else if (!!lang && currentRegionId) {
    return !!newsCategories[currentRegionId] && !!newsCategories[currentRegionId][lang];
  } else {
    return false;
  }
};

const getFeaturedPosts = async (lang: string): Promise<Post[] | undefined> => {
  const { webContext, locale, currentRegionId } = getRequiredContextData();
  const newsCategories = getWebContextCmsCategories(webContext);
  if (!currentRegionId || !webContext || !locale || !lang) {
    console.error("Region, webContext and locale required.");
    return undefined;
  }

  const generalId =
    !!newsCategories[currentRegionId] && !!newsCategories[currentRegionId][locale]
      ? newsCategories[currentRegionId][locale]?.general
      : undefined;

  if (generalId) {
    const opts: NitroFetchOptions<any> = {
      params: {
        per_page: $FEATURED_ITEMS,
        categories: `${generalId}`,
        lang: lang || locale,
      },
    };

    return await fetchPosts(opts);
  } else {
    console.error("General category not found");
    return undefined;
  }
};

const getPosts = async (newOpts?: NitroFetchOptions<any>): Promise<Post[] | undefined> => {
  const { webContext, locale, currentRegionId } = getRequiredContextData();
  const newsCategories = getWebContextCmsCategories(webContext);

  if (!currentRegionId || !webContext || !locale) {
    console.error("Region, webContext and locale required.");
    return undefined;
  }
  const webContextCategories =
    !!newsCategories[currentRegionId] && newsCategories[currentRegionId][locale]
      ? newsCategories[currentRegionId][locale]
      : undefined;
  if (webContextCategories) {
    const allCategories = Object.keys(webContextCategories).map(key => webContextCategories[key]);
    const generalId = webContextCategories?.general;

    const opts: NitroFetchOptions<any> = {
      params: {
        categories: `${generalId}`,
      },
    };

    if (newOpts) {
      opts.params = { ...opts.params, ...newOpts.params };
    }

    if (opts?.params?.categories) {
      //check if category exists in predefined categories
      const matchedCategories: Number[] = opts.params.categories
        .split(",")
        .filter((category: string) => {
          return allCategories.includes(Number(category));
        })
        .join(",");

      if (matchedCategories.length) {
        opts.params.categories = matchedCategories;
      }
    }

    return await fetchPosts(opts);
  } else {
    console.error("General category not found");
    return undefined;
  }
};

const getPost = async (slug: string, lang: string): Promise<Post | undefined> => {
  const opts = {
    params: {
      per_page: 1,
      slug,
      lang,
    },
  };
  const response = await fetchPosts(opts);
  if (response) {
    return response[0];
  } else {
    throw createError({ statusCode: 404 });
  }
};

const isExternal = (categories: number[]): boolean => {
  const { webContext, locale, currentRegionId } = getRequiredContextData();
  const newsCategories = getWebContextCmsCategories(webContext);

  if (!currentRegionId || !webContext || !locale) {
    console.error("Region, webContext and locale required to know if is external.");
    return false;
  }

  const externalId: number | undefined =
    !!newsCategories[currentRegionId] && !!newsCategories[currentRegionId][locale]
      ? newsCategories[currentRegionId][locale].external
      : undefined;

  if (externalId) {
    return categories.includes(externalId);
  } else {
    console.error("externalId is undefined.");
    return false;
  }
};

const parseString = (title: string): string => {
  if (!title) return "";

  const parser = new DOMParser();
  const dom = parser.parseFromString(`${title}`, "text/html");
  return dom.body.textContent || "";
};

const postUiService = (): PostUiService => ({
  getFeaturedPosts,
  getPosts,
  getPost,
  isExternal,
  parseString,
  localeHasNews,
  // checkRepeatedPostInRegions,
});

export default postUiService;

function getCurrentRegionId(): number | undefined {
  const { region } = useCompetitionStore();
  return region?.id;
}

async function fetchPosts(opts: NitroFetchOptions<any>): Promise<Post[] | undefined> {
  const regionId = getCurrentRegionId();
  if (!regionId) {
    console.error("Invalid regionId.");
    return undefined;
  }

  try {
    const data = await $fetch<Post[]>(`/api/cms/${regionId}/news`, opts);
    if (data.length) {
      return data;
    } else {
      console.error("Post/s not found in CMS.");
    }
  } catch (e) {
    console.error("Error fetching posts.");
  }
}

function getRequiredContextData() {
  const { webContext } = useWebContext();
  const locale = useCookie("lang");
  const currentRegionId = getCurrentRegionId();

  return { webContext, locale: locale.value || "es", currentRegionId };
}

// function checkRepeatedPostInRegions(
//   postCategories: number[],
//   webContext: WebContext
// ): { repeated: boolean; cmsKey: string } {
//   let foundObjects = new Set();
//   let firstObject = null;
//
//   for (let cmsKey in newsCategories) {
//     let cms = newsCategories[cmsKey];
//     for (let lang in cms) {
//       if (cms[lang][webContext]) {
//         for (let category in cms[lang][webContext]) {
//           if (postCategories.includes(cms[lang][webContext][category])) {
//             foundObjects.add(cmsKey);
//
//             if (!firstObject) {
//               firstObject = cmsKey;
//             }
//           }
//         }
//       }
//     }
//   }
//
//   return { repeated: foundObjects.size > 1, cmsKey: firstObject };
// }
